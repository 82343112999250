<template>
  <PublicLayout>
    <div class="container">
      <img
        src="@/assets/img_intro.png"
        class="img-intro"
        alt=""
      >

      <h1 v-t="'Legal.title'" />

      <div class="section">
        <span style="text-transform: uppercase; margin: 25px 0;"><font color="#2f5496"><font face="inherit, serif"><font
          size="5"
          style="font-size: 18pt;"
        >CONDITIONS
          générales D'UTILISATION</font></font></font></span>
        <p lang="fr-FR">
          <span style="text-transform: uppercase"><font color="#2f5496"><font face="inherit, serif"><font
            size="4"
            style="font-size: 14pt"
          ><b>Mentions
            légales</b></font></font></font></span>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          Le site&nbsp;cadyco.education (ci-après le «&nbsp;Site&nbsp;»)&nbsp;est
          le site du projet&nbsp;Interreg &quot;CaDyCo Formation&quot;
          proposant une plateforme collaborative innovante d’aide à la
          décision pour l’adaptation de l’offre de formation supérieure
          du territoire Franco-Suisse aux besoins de l’écosystème local.&nbsp;Le
          projet&nbsp;CaDyCo&nbsp;est soutenu par le programme européen en
          coopération transfrontalière Interreg France-Suisse 2014-2020.
        </p>
        <p
          lang="fr-FR"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <b><span style="text-transform: uppercase"><font color="#1a3e78"><font face="inherit, serif">Editeurs
            et Gestionnaires du site</font></font></span></b>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                Le
                site internet&nbsp;
              </font>
            </font>
          </font><font color="#4377b5">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                cadyco.education
              </font>
            </font>
          </font><font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                &nbsp;est
                édité et co-géré par&nbsp;:
              </font>
            </font>
          </font>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                l’Université
                Savoie Mont Blanc (ci-après l’&nbsp;«&nbsp;USMB&nbsp;»),
              </font>
            </font>
          </font>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <b><font color="#333333"><font face="Liberation Sans, serif"><font
            size="2"
            style="font-size: 11pt"
          >Adresse</font></font></font></b><font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                <span style="font-weight: normal">
                  :&nbsp;</span>
              </font>
            </font>
          </font><font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                27
                rue Marcoz 73000 Chambéry - France
              </font>
            </font>
          </font><font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                <span style="font-weight: normal"><br>
                </span>
              </font>
            </font>
          </font><b><font color="#333333"><font face="Liberation Sans, serif"><font
            size="2"
            style="font-size: 11pt"
          >Tél</font></font></font></b><font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                <span style="font-weight: normal">
                  :&nbsp;</span>
              </font>
            </font>
          </font><font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                +33
                04 79 75 85 85
              </font>
            </font>
          </font>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                et
              </font>
            </font>
          </font>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                la
                Haute école spécialisée de Suisse occidentale (ci-après la
                «&nbsp;HES-SO&nbsp;»)
              </font>
            </font>
          </font>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <b><font color="#333333"><font
            size="2"
            style="font-size: 11pt"
          >Adresse
          </font></font></b><font color="#333333">
            <font
              size="2"
              style="font-size: 11pt"
            >
              <span style="font-weight: normal">:&nbsp;Rte
                de Moutier 14, 2800 Delémont - Suisse</span>
            </font>
          </font><b><font color="#333333"><font
            size="2"
            style="font-size: 11pt"
          ><br>
            Tél
          </font></font></b><font color="#333333">
            <font
              size="2"
              style="font-size: 11pt"
            >
              <span style="font-weight: normal">:
                + 41 58 900 00 00</span>
            </font>
          </font>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <b><span style="text-transform: uppercase"><font color="#1a3e78"><font face="inherit, serif">HÉBERGEMENT</font></font></span></b>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0.16in"
        >
          <font color="#333333">
            <font face="Liberation Sans, serif">
              <font
                size="2"
                style="font-size: 11pt"
              >
                Le
                Site est hébergé au GRICAD à Grenoble
                (https://gricad.univ-grenoble-alpes.fr/)
              </font>
            </font>
          </font>
        </p>
        <p
          lang="fr-FR"
          style="text-transform: uppercase; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0in"
        >
          <b><span style="text-transform: uppercase"><font color="#1a3e78"><font face="inherit, serif"><font
            size="4"
            style="font-size: 14pt"
          >Conditions
            d’utilisation</font></font></font></span></b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            1 : Définitions</b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Site :
          désigne le site internet «&nbsp;cadyco.education&nbsp;» édité et
          géré par la HES-SO et l’USMB.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Utilisateur
          : désigne l’ensemble des internautes, inscrits ou non, connecté
          sur le Site.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Utilisateur
          membre : désigne l’Utilisateur qui a&nbsp;créé&nbsp;son profil
          au sein de l’espace membre du Site. Cet espace lui permet de
          communiquer sur le forum du Site, de publier des articles sur le blog
          et de gérer son profil.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            2 : Champ d’application&nbsp;</b>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Les
          présentes conditions générales d’utilisation (ci-après&nbsp;«
          CGU ») régissent l’utilisation du Site accessible via l’adresse
          URL suivante cadyco.education et s’appliquent pour
          l’ensemble du Site.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Ces CGU
          sont les seules applicables et remplacent toutes autres conditions,
          sauf dérogation préalable, expressément acceptée par les éditeurs
          du Site.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Veuillez
          prendre en considération que les présentes conditions générales
          d’utilisation sont susceptibles d’être modifiées ou complétées
          à tout moment par les&nbsp;éditeurs du Site en France ou en Suisse,
          notamment en vue de se conformer à toute évolution législative,
          réglementaire, jurisprudentielle ou technologique, ou de répondre à
          un impératif technique ou un besoin d’organisation des services.
          Dans un tel cas, la date de leur mise à jour sera clairement
          identifiée en tête des présentes conditions générales
          d’utilisation. Ces modifications sont opposables à l’Utilisateur
          et l’engagent dès leur mise en ligne. <font color="#1d1d1f">
            <span style="letter-spacing: -0.3pt"><span style="background: #ffffff">Il
              est de la responsabilité de l’Utilisateur</span></span>
          </font><font color="#1d1d1f">
            <font face="Arial, serif">
              <span style="letter-spacing: -0.3pt"><span style="background: #ffffff" /></span>
            </font>
          </font><font color="#1d1d1f">
            <span style="letter-spacing: -0.3pt"><span style="background: #ffffff">de
              consulter les présentes conditions générales d’utilisation à
              chaque fois qu’il accède au Site pour voir si des modifications y
              ont été apportées. Si l’Utilisateur continue à utiliser le Site
              après publication des modifications apportées, cela signifie qu’il
              accepte lesdites modifications.</span></span>
          </font>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="letter-spacing: -0.3pt; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="letter-spacing: -0.3pt; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            3&nbsp;: Acceptation des conditions générales d’utilisation</b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En
          accédant au Site, respectivement en utilisant ses services,
          l’Utilisateur reconnaît et confirme qu’il a lu et compris les
          présentes conditions générales d’utilisation, lesquelles sont
          exclusivement rédigées en français, et qu’il accepte d’être
          lié par les termes qui y sont contenus. L’Utilisateur garantit
          qu’il dispose de la capacité juridique pour accepter les présentes
          conditions générales d’utilisation. Si l’Utilisateur ne les
          accepte pas intégralement, il est prié de ne pas utiliser ce Site
          et de renoncer à tout accès à celui-ci.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En créant
          un compte sur le Site, les Utilisateurs sont seuls responsables de la
          confidentialité des données qu’ils ont renseignées (identifiant,
          mot de passe, …). Le Site décline toute responsabilité en cas de
          violation de la confidentialité des données des Utilisateurs.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En cas de
          violation des présentes conditions générales d’utilisation, ou
          de la loi (française et/ou suisse), l’Utilisateur s’expose à
          des sanctions définies par les éditeurs du Site, par exemple&nbsp;:
          retrait temporaire ou permanent du droit d’utiliser le Site,
          suppression temporaire ou permanente de tout contenu publié par
          l’Utilisateur concerné sur le Site, etc.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="letter-spacing: -0.3pt; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0in"
        >
          Pour toute question
          concernant le Site, vous pouvez contacter le webmaster à l’adresse
          suivante :&nbsp;<a href="mailto:contact@cadyco.education">contact@cadyco.education</a>.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            4 : Services&nbsp;</b>
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              communauté&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              espace&nbsp;d’échanges
              (le mur)&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              communication&nbsp;d’informations
              avec les contacts et les listes de contacts&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              accès&nbsp;aux
              informations des objets&nbsp;CaDyCo&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              <a name="_GoBack" />
              accès&nbsp;aux indicateurs proposés par&nbsp;CaDyCo&nbsp;
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            5 : Droits de la propriété intellectuelle&nbsp;</b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          La
          totalité du contenu composant le Site est protégée par la
          législation applicable relative au droit d’auteur et au droit des
          marques : ce peut être notamment le logo du Site, celui de ses
          partenaires, les textes diffusés, des photographies, des dessins,
          des séquences, des phonogrammes, des vidéoclips, des symboles,
          etc.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Les droits
          relatifs à ces éléments sont réservés. En conséquence, toute
          reproduction, représentation, utilisation, téléchargement et
          partage des contenus du Site ne respectant pas les conditions
          définies par la licence CC–BY–NC-ND régissant l’usage des
          contenus sur le Site sont strictement proscrites. Toute adaptation,
          modification, incorporation, traduction, commercialisation,
          partielles ou intégrales par quelque procédé que ce soit (papier,
          numérique, …) sont interdites, conformément à l’usage défini
          par la licence CC-BY-NC-ND, sous réserve des exceptions visées à
          l’article L.122.5 du Code de la propriété intellectuelle, sous
          peine de constituer un délit de contrefaçon de droit d’auteur
          et/ou de dessins et modèles et/ou de marque, puni de deux ans
          d’emprisonnement et de 150.000 euros d’amende.&nbsp;Ces
          interdictions pourraient être levées à condition d’obtenir au
          préalable des éditeurs du Site une autorisation écrite. toute
          reproduction, représentation, utilisation, adaptation, modification,
          incorporation, traduction, commercialisation, partielles ou
          intégrales par quelque procédé et sur quelque support que ce soit
          (papier, numérique,…) sont interdites sans l’autorisation écrite
          préalable des éditeurs du Site, sous réserve des exceptions visées
          à l’article L.122.5 du Code de la propriété intellectuelle, sous
          peine de constituer un délit de contrefaçon de droit d’auteur
          et/ou de dessins et modèles et/ou de marque, puni de deux ans
          d’emprisonnement et de 150.000 euros d’amende.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          L’Utilisateur
          qui publierait des contenus sur le Site, en tant qu’utilisateur
          final du dit contenu, doit s’assurer d’être en conformité avec
          le droit d’auteur et le droit des marques concernant la publication
          et la diffusion sur le Site. En cas de contentieux, l’Utilisateur
          devra pouvoir prouver qu’il est soit bien l’auteur et le
          propriétaire du contenu qu’il publie, soit qu’il est bien en
          conformité avec le droit d’auteur ou des marques pour la diffusion
          du dit contenu. Le Site décline toute responsabilité en cas de
          manquement au droit d’auteur et/ou au droit de la marque commis par
          l’Utilisateur.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          style="margin-bottom: 0in"
        >
          <b>Article 6 : Politique
            de confidentialité&nbsp;</b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Cet
          article définit et vous informe de la manière
          dont&nbsp;cadyco.education&nbsp;utilise et protège les informations
          que vous nous transmettez, le cas échéant, lorsque vous utilisez le
          présent Site accessible à partir de
          l’URL&nbsp;suivante:&nbsp;<a href="https://cadyco.education/">https://cadyco.education</a>.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          D’une
          manière générale, il vous est possible de visiter le Site sans
          communiquer aucune information personnelle vous concernant. En toute
          hypothèse, vous n’êtes en aucune manière obligé de transmettre
          ces informations à&nbsp;cadyco.education. Néanmoins, en cas de
          refus, vous n’accéderez pas à certaines informations ou services.
          À ce titre en effet,&nbsp;cadyco.education&nbsp;vous demande de vous
          authentifier sur la plateforme et peut être amené à vous demander
          de renseigner (ci-après vos « Informations Personnelles ») :&nbsp;
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              Prénom&nbsp;et
              nom de famille&nbsp;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              Adresse&nbsp;de
              courrier électronique
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              Statut
              (à choisir parmi la liste proposée)
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              Le
              code postal et le pays
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En
          fournissant ces informations, vous acceptez expressément qu’elles
          soient traitées par&nbsp;cadyco.education, aux fins indiquées au
          chiffre 2 ci-dessous.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Conformément
          au Règlement (UE) 2016/679 du Parlement européen et du Conseil du
          27 avril 2016 relatif à la protection des personnes physiques à
          l’égard du traitement des données à caractère personnel et à
          la libre circulation de ces données (RGPD), à la Loi n°78-17 du 6
          janvier 1978 relative à l’informatique, aux fichiers et aux
          libertés,&nbsp;et à la Convention intercantonale relative à la
          protection des données et à la transparence dans les cantons du
          Jura et de Neuchâtel des 8 et 9 mai 2012
          (CPDT-JUNE),&nbsp;cadyco.education&nbsp;vous informe des points
          suivants
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          1.
          Identité des responsables du traitement&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          La
          responsabilité est partagée comme suit :
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En Suisse,
          le responsable du traitement est la HES-SO ayant son siège social à
          Rte de Moutier 14, 2800 Delémont - Suisse. Tél. : + 41 58 900 00 00
          ;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En France,
          le responsable du traitement est l'USMB ayant son siège social 27
          rue Marcoz 73000 Chambéry. Tél : +33479758585.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.98in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          2.
          Finalités du traitement&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          cadyco.education&nbsp;est
          susceptible de traiter vos Informations Personnelles :&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          (a)
          aux&nbsp;fins de vous fournir les informations ou les services que
          vous avez demandés de recevoir (utilisation des indicateurs et des
          objets sur notre Site ou envoi de&nbsp;nos newsletters&nbsp;suite à
          inscription de votre part) ; et/ou&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          (b)&nbsp;
          aux&nbsp;fins de recueillir des informations nous permettant
          d’améliorer notre Site, nos objets et nos indicateurs (notamment
          par le biais de cookies, sachant que l'outil utilisé, à savoir
          MATOMO, préserve la vie privée des utilisateurs) ; et/ou&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          (c)
          aux&nbsp;fins de pouvoir vous contacter à propos de différents
          évènements relatifs à&nbsp;cadyco.education, incluant notamment la
          mise à jour d’évènements ou d’actualités.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 1.27in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          3. Durée
          de conservation&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Vos
          Informations Personnelles sont conservées
          par&nbsp;cadyco.education&nbsp;uniquement pour le temps correspondant
          à l’utilisation de la plateforme en mode authentifié. La durée
          de conservation ne saurait en tout état de cause excéder 12 mois
          après votre décision de supprimer votre compte personnel.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.98in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          4. Droits
          Informatiques et Libertés&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Vous
          disposez des droits suivants concernant vos Informations
          Personnelles, que vous pouvez exercer en nous écrivant à l’adresse
          e-mail (contact@cadyco.education), à l’adresse postale (cf.
          chiffre 1 ci-dessus) ou en utilisant le formulaire de contact présent
          sur le Site à l’url suivante : https://cadyco.education/contact.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.98in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <ol type="a">
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              Droit
              d’accès et de communication des données&nbsp;
            </p>
          </li>
        </ol>
        <p
          lang="fr-FR"
          align="justify"
          style="text-indent: 0.49in; margin-bottom: 0in"
        >
          Vous avez la faculté d’accéder aux Informations Personnelles qui
          vous concernent.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.98in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          Cependant, en raison de l’obligation de sécurité et de
          confidentialité dans le traitement des données à caractère
          personnel qui incombe à&nbsp;cadyco.education, vous êtes informé
          que votre demande sera traitée sous réserve que vous rapportiez la
          preuve de votre identité, notamment par la production d’un scan de
          votre titre d’identité valide (en cas de demande par notre
          formulaire électronique dédié) ou d’une photocopie signée de
          votre titre d’identité valide (en cas de demande adressée par
          écrit).&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.98in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in"
        >
          cadyco.education&nbsp;vous
          informe qu’il sera en droit, le cas échéant, de s’opposer aux
          demandes manifestement abusives (de par leur nombre, leur caractère
          répétitif ou systématique). Pour vous aider dans votre démarche,
          notamment si vous désirez exercer votre droit d’accès par le
          biais d’une demande écrite à l’adresse postale&nbsp;mentionnée
          au chiffre 1 ci-dessus, vous trouverez en cliquant sur le lien
          suivant un modèle de courrier élaboré par la Commission Nationale
          de l’Informatique et des Libertés en France (la « CNIL »), ou un
          modèle de courrier élaborés selon la Convention intercantonale
          relative à la protection des données et à la transparence&nbsp;:
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              https://www.ppdt-june.ch/fr/Documentation/Modeles-Documents/PdD-Particuliers-AccesPerso-Acces-donnees/Demande-d-acces-a-ses-donnees-personnelles.html
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              <font color="#0563c1">
                <u><a href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces">https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces</a></u>
              </font>&nbsp;&nbsp;
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <ol
          type="a"
          start="2"
        >
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              Droit
              de rectification des données&nbsp;
            </p>
          </li>
        </ol>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          Au titre de ce droit, vous êtes habilité à demander la
          rectification, la mise à jour, le verrouillage ou encore
          l’effacement des données vous concernant qui peuvent s’avérer
          le cas échéant inexactes, erronées, incomplètes ou
          obsolètes.&nbsp;Vous pouvez également définir des directives
          générales et particulières relatives au sort des données à
          caractère personnel après votre décès. Le cas échéant, les
          héritiers d’une personne décédée peuvent exiger de prendre en
          considération le décès de leur proche et/ou de procéder aux mises
          à jour nécessaires. Pour vous aider dans votre démarche, notamment
          si vous désirez exercer, pour votre propre compte ou pour le compte
          de l’un de vos proches décédé, votre droit de rectification par
          le biais d’une demande écrite à l’adresse postale&nbsp;mentionnée
          au chiffre 1 ci-dessus, vous trouverez en cliquant sur le lien
          suivant un modèle de courrier élaboré par la CNIL, ou un modèle
          de courrier élaborés selon la Convention intercantonale relative à
          la protection des données et à la transparence&nbsp;des cantons de
          Jura et Neuchâtel :&nbsp;
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              <font color="#0563c1">
                <u><a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees">https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees</a></u>
              </font>
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              https://www.ppdt-june.ch/fr/Documentation/Modeles-Documents/PdD-Particuliers-AccesPerso-Rectification/Rectification-donnees-personnelles.html
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.98in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <ol
          type="a"
          start="3"
        >
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              Droit
              d’opposition&nbsp;
            </p>
          </li>
        </ol>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          L’exercice de ce droit n’est possible que dans l’une des deux
          situations suivantes : lorsque l’exercice de ce droit est fondé
          sur des motifs légitimes ; ou lorsque l’exercice de ce droit vise
          à faire obstacle à ce que les données recueillies soient utilisées
          à des fins de prospection commerciale. Pour vous aider dans votre
          démarche, notamment si vous désirez exercer votre droit
          d’opposition par le biais d’une demande écrite adressée à
          l’adresse postale indiquée au chiffre 1 ci-dessus, vous trouverez
          en cliquant sur le lien suivant un modèle de courrier élaboré par
          la CNIL, ou un modèle de courrier élaborés selon la Convention
          intercantonale relative à la protection des données et à la
          transparence&nbsp;des cantons de Jura et Neuchâtel :&nbsp;
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              <font color="#0563c1">
                <u><a href="https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet">https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet</a></u>
              </font>&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              https://www.ppdt-june.ch/fr/Documentation/Modeles-Documents/PdD-Particuliers-AccesPerso-Constatation/Demande-d-abstention-suppression-constatation-d-un-traitement-illicite.html
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.98in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          5. Délai
          de réponse&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          cadyco.education&nbsp;s’engage
          à répondre à votre demande d’accès, de rectification ou
          d’opposition ou toute autre demande complémentaire d’informations
          dans un délai raisonnable qui ne saurait dépasser 3 mois à compter
          de la réception de votre demande.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.98in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          6. Plainte
          auprès de l’autorité compétente&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Si vous
          considérez que cadyco.education ne respecte pas ses obligations au
          regard de vos Informations Personnelles, vous pouvez adresser une
          plainte ou une demande auprès de l’autorité compétente.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En France,
          l’autorité compétente est la CNIL à laquelle vous pouvez
          adresser une demande par voie électronique en cliquant sur le lien
          suivant :&nbsp;
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              <font color="#0563c1">
                <u><a href="https://www.cnil.fr/fr/plaintes/internet">https://www.cnil.fr/fr/plaintes/internet</a></u>
              </font>&nbsp;&nbsp;
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En Suisse
          il s’agira du préposé à la protection des données et à la
          transparence Jura Neuchâtel, dont le site suivant permettra le
          contact par voie electronique&nbsp;:
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              https://www.ppdt-june.ch/fr/Documentation/Modeles-Documents/Modeles-de-Documents.html#PD_Demandeurs
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            7 : Limitation de responsabilité&nbsp;&nbsp;</b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <span style="font-weight: normal"><span style="background: #ffffff">Les
            éditeurs du Site déclinent, dans toute la mesure permise par la
            loi, toute responsabilité envers les Utilisateurs et toute autre
            personne en ce qui concerne le contenu du Site et ses services,&nbsp;</span></span><span style="background: #ffffff">en
            particulier par rapport à d’éventuels dommages-intérêts, pertes
            (de production, de revenus, de bénéfices) ou autres qui pourraient
            en découler, directement ou indirectement, même en cas de faute des
            éditeurs du Site.&nbsp;</span>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Les
          éditeurs du Site ne garantissent pas que le Site ou un contenu y
          figurant sera toujours disponible ou accessible sans interruption.
          Ils ne sauraient être tenus responsables des problèmes techniques
          rencontrés sur le Site/d’une indisponibilité du Site, quel qu’en
          soit le motif, le moment ou la durée.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Le contenu
          mis à disposition sur le Site est fourni à titre informatif.
          L’existence d’un lien de ce Site vers un autre site ne constitue
          en aucun cas une validation de ce site ou de son contenu de la part
          des éditeurs du Site.&nbsp;Les éditeurs du Site ne sauraient être
          tenus responsables des problèmes rencontrés sur tous les autres
          sites et/ou blogs vers lesquels des liens sont établis, ou de toute
          autre information publiée sur ces sites et/ou blogs, ainsi que des
          conséquences de leur utilisation.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Le Site
          est conçu pour répondre à un usage non professionnel, raisonnable
          et normal par les Utilisateurs.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Il
          appartient à l’Utilisateur d’utiliser les informations diffusées
          par l’intermédiaire du Site avec discernement et esprit critique.
          La responsabilité des éditeurs du Site ne saurait être engagée du
          fait des informations, opinions et recommandations formulées par des
          tiers.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          De la même
          manière les éditeurs du Site ne sauraient être tenus pour
          responsables d’erreurs, d’omissions ou de résultats qui
          pourraient être obtenus par un mauvais usage des informations
          diffusées par l’intermédiaire du Site.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            8 : Liens hypertextes&nbsp;</b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Les liens
          générés par les Utilisateurs membres vers d’autres sites et/ou
          blogs sont communiqués à titre indicatif et ne sauraient engager la
          responsabilité des éditeurs du Site, tant pour leurs contenus que
          leurs conditions d’accès.&nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          La
          création de liens hypertextes est autorisée sous réserve de
          respecter les conditions suivantes :&nbsp;
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              la&nbsp;source
              qui pointera grâce à un lien hypertexte directement sur le contenu
              visé doit être précisée&nbsp;;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              les&nbsp;informations
              utilisées ne doivent l’être qu’à des fins personnelles,
              éducatives, associatives ou professionnelles, toute utilisation à
              des fins commerciales ou publicitaires étant interdite.&nbsp;
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            9 : Conditions de modération du mur&nbsp;</b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          Chaque
          Utilisateur s’engage à respecter les règles de conduites
          suivantes et s’interdit, sans que cette liste soit exhaustive, de
          publier :&nbsp;
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              des&nbsp;contenus
              contrevenants aux droits d’autrui (vie privée, droit
              à&nbsp;l’image...) ou à caractère diffamatoire, injurieux,
              obscènes ou offensants ou portant atteinte à la protection des
              enfants et des adolescents ;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              des&nbsp;contenus
              présentant un caractère violent ou pornographique ou qui
              encourageraient la commission de crimes ou délits ou qui
              encourageraient à la discrimination et à la haine raciale, au
              suicide ou aux comportements révisionnistes et négationnistes ;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              des&nbsp;contenus
              divulguant des informations permettant l’identification nominative
              et précise des utilisateurs du site, telles&nbsp;que adresse,
              postale et/ou électronique, numéros de téléphone ;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              des&nbsp;contenus
              contrefaisant des en-têtes, officielles ou non, ou manipulant de
              quelque manière que ce soit l’identifiant de manière à
              dissimuler l’origine ou la source du contenu initial transmis via
              le Site, induisant en erreur les autres utilisateurs en usurpant une
              identité ;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              des&nbsp;contenus
              ayant pour finalité de diffuser des messages commerciaux,
              publicitaires ou promotionnels ou de la propagande ;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              des&nbsp;contenus
              diffusant des conseils ou commentaires contrevenant aux dispositions
              légales et réglementaires ;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              des&nbsp;contenus
              contraires aux droits d’auteurs (notamment reproduction,
              représentation ou diffusion d’une œuvre), aux droits voisins, au
              droit des marques ou au droit applicable aux bases de données ;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              des&nbsp;contenus
              traitant de la copie de logiciels commerciaux pour un usage autre
              qu’une copie de sauvegarde dans les conditions prévues par le
              Code de la propriété intellectuelle.&nbsp;
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
        &nbsp;
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          En outre,
          chaque Utilisateur s’interdit, sauf autorisation légale ou
          autorisation préalable des titulaires des droits des contenus
          concernés :&nbsp;
        </p>
        <ul>
          <li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              d’utiliser&nbsp;le
              Site comme support de diffusion de messages portant atteinte à
              l’image des éditeurs du Site;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              de&nbsp;reproduire
              et/ou exploiter, dans un but commercial, tout ou partie des contenus
              mis en ligne dans le cadre d’utilisation du Site, sauf
              autorisation légale ou autorisation préalable des titulaires des
              droits des contenus concernés ;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              d’extraire&nbsp;ou
              de collecter des adresses électroniques ou d’autres informations
              relatives à des Utilisateurs aux fins d’envoi de communications
              non sollicitées ;&nbsp;
            </p>
          </li><li>
            <p
              lang="fr-FR"
              align="justify"
              style="margin-bottom: 0in"
            >
              de&nbsp;porter,
              dans les messages postés sur le Site, des liens hypertextes ou des
              commentaires se référant ou renvoyant à des sites extérieurs ou
              renvoyant à des informations contenant des virus informatiques ou
              de contournement de dispositifs techniques de protection ou
              permettant tout acte de piratage ou permettant d’entraver ou
              perturber l’accès et l’utilisation du Site, des réseaux, des
              serveurs connectés au Site.&nbsp;
            </p>
          </li>
        </ul>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-bottom: 0in"
        >
          <b>Article
            10 – Droit applicable&nbsp;et for</b>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          Il est expressément entendu que tout litige lié à l’utilisation
          du Site ou à l’exécution, l’interprétation ou la validité des
          présentes conditions générales d’utilisation sera soumis au
          droit français, à l’exclusion de tout litige lié au traitement
          des données personnelles par la HES-SO qui sera soumis au droit
          suisse, et ce i<span style="letter-spacing: -0.3pt"><span style="background: #ffffff">ndépendamment
            des dispositions en matière de conflits de lois</span></span>.
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          align="justify"
          style="margin-left: 0.49in; margin-bottom: 0in"
        >
          En cas de litige, l’Utilisateur s’engage à contacter en priorité
          les éditeurs du Site afin de tenter de résoudre ce litige à
          l’amiable.&nbsp;A défaut de conciliation, les tribunaux de
          Chambéry (France) ou les tribunaux du canton du Jura (Suisse) seront
          seuls&nbsp;compétents.
        </p>
        <p
          lang="fr-FR"
          style="line-height: 110%; margin-left: 0.49in; margin-bottom: 0in"
        >
          <br>
        </p>
        <p
          lang="fr-FR"
          class="western"
          style="line-height: 100%; margin-bottom: 0in"
        >
          <br>
        </p>
      </div>
    </div>
  </PublicLayout>
</template>

<script>
import PublicLayout from '@/router/layouts/PublicLayout.vue'

export default {
  name: 'Legal',
  components: {
    PublicLayout
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  position: relative;
  padding: 40px 15% 0 15%;
  overflow-y: auto;
  background-color: $public-bg;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-intro {
    position: absolute;
    top: -25px;
    left: -150px;
    height: 800px;
    z-index: 0;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 36px;
    color: $color-light-text;
    z-index: 1;
  }

  .section {
    margin: 50px 0 100px 0;
    padding: 0 20px;
    max-width: 832px;
    position: relative;
    background-color: $color-body-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;

  }
}

</style>
